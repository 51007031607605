import { pageLike } from "./PageLike.js";
import { eventResponses } from "./EventResponses";
import { postEngagement } from "./PostEngagement.js";
import { traffic } from "./Traffic.js"
import { conversions } from "./Conversions.js"
import { reach } from "./Reach.js"
import { videoViews } from "./VideoViews.js";
import { equals } from "../../helper.js"
import { brandAwareness } from "./BrandAwareness.js"
import { existingPosts, getExistingPostConfig } from "./ExistingPosts.js"
import { appInstall } from "./AppInstall.js"
import { leadGeneration } from "./LeadGeneration.js"
import { messages } from "./Messages.js"
import { storeVisits } from "./StoreVisits.js"
import * as fbEnums from "../../Constants/FacebookEnums.js";
import { productCatalogSales } from "./ProductCatalogSales.js";
import FacebookCampaignLauncherConfig from "../../Mixins/FacebookCampaignLauncherConfig.js"

class FacebookConfig {
  constructor (Config) {
    this.config = Config
  }
  getConfig (configName) {
    if (this.config.campaign.buyType == 1) {
      let auctionConfig = { ...this.getAuctionConfig(configName) };

      if (this.showExistingPosts() &&
        (this.config.adset.destination == fbEnums.destinationType.Undefined ||
        this.config.adset.destination == fbEnums.destinationType.Page ||
        this.config.adset.destination == fbEnums.destinationType.Post ||
        this.config.adset.destination == fbEnums.destinationType.OnPage ||
        this.config.adset.destination == fbEnums.destinationType.Video)
      ) {
        // objectives supporting 'Use existing post': https://facebook.com/business/help/162407440982808
        auctionConfig['existing posts'] = getExistingPostConfig(this.config)[configName].posts;
      }

      return auctionConfig;
    } else {
      let rnfConfig = { ...this.getRNFConfig(configName) }
      // if (rnfConfig["collection"]) {
      //   // remove collection in case buy type in RnF
      //   delete rnfConfig["collection"];
      // }
      if (this.showExistingPosts() &&
        (this.config.adset.destination == fbEnums.destinationType.Undefined ||
        this.config.adset.destination == fbEnums.destinationType.Page)
      ) {
        rnfConfig['existing posts'] = existingPosts[configName].posts;
      }
      return rnfConfig;
    }
  }
  showExistingPosts () {
    return this.isPostEngagementObjective(this.config) ||
      this.isConversionsObjective(this.config) ||
      this.isTrafficObjective(this.config) ||
      this.isVideoViewsObjective(this.config) ||
      this.isBrandAwarenessObjective(this.config) ||
      this.isReachObjective(this.config) ||
      this.isPageLikesObjective(this.config);
  }
  getObjectiveClass () {
    if (this.isPageLikesObjective(this.config)) {
      return pageLike;
    } else if (this.isEventResponsesObjective(this.config)) {
      return eventResponses;
    } else if (this.isPostEngagementObjective(this.config)) {
      return postEngagement
    } else if (this.isConversionsObjective(this.config)) {
      return conversions;
    } else if (this.isTrafficObjective(this.config)) {
      return traffic;
    } else if (this.isVideoViewsObjective(this.config)) {
      return videoViews;
    } else if (this.isDPAObjective(this.config, true)) {
      return productCatalogSales;
    } else if (this.isLeadGenerationObjective(this.config)) {
      return leadGeneration;
    } else if (this.isAppInstallsObjective(this.config)) {
      return appInstall;
    } else if (this.isBrandAwarenessObjective(this.config)) {
      return brandAwareness;
    } else if (this.isReachObjective(this.config)) {
      return reach
    } else if (this.isMessagesObjective(this.config)) {
      return messages;
    } else if (this.isStoreVisitsObjective(this.config)) {
      return storeVisits;
    }
  }
  getAuctionConfig (configName) {
    if (this.isPageLikesObjective(this.config)) {
      return pageLike[configName]
    } else if (this.isEventResponsesObjective(this.config)) {
      return eventResponses[configName]
    } else if (this.isPostEngagementObjective(this.config)) {
      return postEngagement[configName]
    } else if (this.isConversionsObjective(this.config)) {
      return conversions[configName](this.config)
    } else if (this.isTrafficObjective(this.config)) {
      return traffic[configName](this.config)
    } else if (this.isVideoViewsObjective(this.config)) {
      return {
        "single": videoViews[configName]["single"]
      }
    } else if (this.isDPAObjective(this.config)) {
      return productCatalogSales[configName]
    } else if (this.isLeadGenerationObjective(this.config)) {
      return leadGeneration[configName](this.config)
    } else if (this.isAppInstallsObjective(this.config)) {
      return appInstall[configName](this.config)
    } else if (this.isBrandAwarenessObjective(this.config)) {
      return brandAwareness[configName]
    } else if (this.isReachObjective(this.config)) {
      return reach[configName]
    } else if (this.isStoreVisitsObjective(this.config)) {
      return storeVisits[configName](this.config)
    } else if (this.isMessagesObjective(this.config)) {
      return messages[configName](this.config)
    }
  }
  getRNFConfig (configName) {
    if (this.isPostEngagementObjective(this.config)) {
      return this.getRnfConfigBasedOnAdFormats(eventResponses, configName)
    } else if (this.isConversionsObjective(this.config)) {
      return this.getRnfConfigBasedOnAdFormats(conversions, configName, this.config)
    } else if (this.isTrafficObjective(this.config)) {
      return this.getRnfConfigBasedOnAdFormats(traffic, configName, this.config)
    } else if (this.isVideoViewsObjective(this.config)) {
      return this.getRnfConfigBasedOnAdFormats(videoViews, configName)
    } else if (this.isBrandAwarenessObjective(this.config)) {
      return this.getRnfConfigBasedOnAdFormats(brandAwareness, configName)
    } else if (this.isReachObjective(this.config)) {
      return this.getRnfConfigBasedOnAdFormats(reach, configName)
    }
  }
  getRNFConfigForVideoViews (configName) {
    let creativeFields = this.config.adset.adformat
    if (equals(creativeFields, ["Video", "Stories Carousel", "Instant Experience"])) {
      return videoViews[configName]["Video;Stories_Carousel;Instant_Experience"]
    } else if (equals(creativeFields, ["Video", "Instant Experience"])) {
      return videoViews[configName]["Video;360_Video;Instant_Experience"]
    } else if (equals(creativeFields, ["Video", "360 Video", "Instant Experience"])) {
      return videoViews[configName]["Video;360_Video;Instant_Experience"]
    } else if (equals(creativeFields, ["Video"])) {
      return videoViews[configName]["Video;360_Video;Instant_Experience"]
    } else if (equals(creativeFields, ["Video", "Stories Carousel"])) {
      return videoViews[configName]["Video;360_Video"]
    } else if (equals(creativeFields, ["Video", "360 Video"])) {
      return videoViews[configName]["Video;360_Video;Instant_Experience"]
    }
  }
  getRnfAdFormats () {
    var objective = this.getObjectiveClass();
    return Object.keys(objective.rnfAdformats);
  }
  getRnfConfigBasedOnAdFormats (objective, configName, campaignConfig) {
    let adformats = this.config.adset.adformat;
    let adSheetsRequired = [];
    campaignConfig = campaignConfig || false;
    let supportedObjectiveAdFormats = objective.rnfAdformats;
    Object.keys(supportedObjectiveAdFormats).forEach(function (item, index) {
      if (adformats.includes(item)) {
        adSheetsRequired.push(...supportedObjectiveAdFormats[item].adSheets)
      }
    })
    var adSheetsDeduped = Array.from(new Set(adSheetsRequired));
    var rnfConfig = {}
    adSheetsDeduped.forEach(e => {
      if (campaignConfig) {
        rnfConfig[e] = objective[configName](campaignConfig)[e];
      } else {
        rnfConfig[e] = objective[configName][e];
      }
    })
    return rnfConfig;
  }
  getPlacementsByObjectiveAndAdformats () {
    if (this.isPostEngagementObjective(this.config)) {
      return this.getRnfPlacements(eventResponses)
    } else if (this.isConversionsObjective(this.config)) {
      return this.getRnfPlacements(conversions, this.config)
    } else if (this.isTrafficObjective(this.config)) {
      return this.getRnfPlacements(traffic, this.config)
    } else if (this.isVideoViewsObjective(this.config)) {
      return this.getRnfPlacements(videoViews)
    } else if (this.isBrandAwarenessObjective(this.config)) {
      return this.getRnfPlacements(brandAwareness)
    } else if (this.isReachObjective(this.config)) {
      return this.getRnfPlacements(reach)
    }
  }
  getRnfPlacements (objective, campaignConfig) {
    campaignConfig = campaignConfig || false;
    var allPlacementsConfig = Object.keys(fbEnums.platforms).reduce((p, e) => {
      p[fbEnums.platforms[e]] = [...Object.keys(fbEnums.positions[e]).map(f => { return fbEnums.positions[e][f] })]
      return p;
    }, {});
    var selectedAdFormats = this.config.adset.adformat;
    var validPlacements = [];
    validPlacements = selectedAdFormats.reduce((previousValue, currentValue) => {
      var placementConfig = objective.rnfAdformats[currentValue].placements;
      var validPlacementsForAdFormat = Object.keys(placementConfig);
      var previousPlacements = Object.keys(previousValue);
      var commonPlacements = previousPlacements.filter(e => validPlacementsForAdFormat.includes(e));
      var effectivePlacements = {}
      commonPlacements.forEach(e => {
        effectivePlacements[e] = previousValue[e].filter(f => placementConfig[e].includes(f));
      })
      return effectivePlacements;
    }, allPlacementsConfig)
    return validPlacements;
  }
  displayHeaderNameLookup () {
    return {
      "HOT_Ad Name": "Ad Name",
      "HOT_Ad Title": "Headline",
      "HOT_Call To Action": "Call To Action",
      "HOT_Deep Link": "Deep Link",
      "HOT_Deep Link Destination": "Deep link destination",
      "HOT_Description": "Description",
      "HOT_Display Link": "Display Link",
      "HOT_Event URL": "Event URL",
      "HOT_Headline": "Headline",
      "HOT_Image/Video": "Image/Video",
      "HOT_Instant Experience": "Instant Experience",
      "HOT_Link": "Link",
      "HOT_Message": "Message",
      "HOT_Post ID": "Post ID",
      "HOT_Post Name": "Post Name",
      "HOT_Preview Ad": "Preview Ad",
      "HOT_Primary Text": "Primary Text",
      "HOT_See More Display Url": "See More Display Url",
      "HOT_See More Url": "See More Url",
      "HOT_Targets": "Target",
      "HOT_Locations": "Location",
      "HOT_Text": "Text",
      "HOT_Url Tags": "Url Tags",
      "HOT_Video": "Video",
      "HOT_Website Url": "Website Url",
      "HOT_Placements": "Placements",
      "HOT_Lead Form Id": "Lead Form Id",
      "HOT_Catalog Options": "Catalog Options",
      "HOT_News Feed Link Description": "News Feed Link Description",
      "HOT_Intro Card: Image": "Intro Card: Image",
      "HOT_Intro Card: Headline": "Intro Card: Headline",
      "HOT_Intro Card: News Feed Link Description": "Intro Card: News Feed Link Description",
      "HOT_Intro Card: Website Url": "Intro Card: Website Url",
      "HOT_Catalog Card: Creative": "Catalog Card: Creative",
      "HOT_Catalog Card: Headline": "Catalog Card: Headline",
      "HOT_Catalog Card: News Feed Link Description": "Catalog Card: News Feed Link Description",
      "HOT_Android Url": "Android Url",
      "HOT_iOS Url": "iOS Url",
      "HOT_Windows Phone Url": "Windows Phone Url",
      "HOT_Cover Image/Video": "Cover Image/Video",
      "HOT_Button Label": "Button Label",
      "HOT_Button Destination": "Button Destination",
      "HOT_Products Featured": "Products Featured",
      "HOT_Product Set": "Product Set",
      "HOT_StartDate": "Start Date",
      "HOT_EndDate": "End Date",
      "HOT_Phone Number": "Phone Number",
      "HOT_StartTime": "Start Time",
      "HOT_EndTime": "End Time",
      "HOT_AddMap": "Add Map Card",
      "HOT_LineItemFractions": "Line Item Fractions",
      "HOT_ViewTags": "View Tags",
      "HOT_Instant Experience Name": "Instant Experience Name",
      "HOT_ProductSets": "Product Set",
      "HOT_Ad Status": "Ad Status",
      "HOT_Standard_Enhancements": "Standard Enhancements",
      "HOT_Image_Enhancements": "Image Enhancements",
      "HOT_3d_Animation": "3d Animation",
      "HOT_Music": "Music",
      "HOT_Video_Thumbnail": "Video Thumbnail",
      "HOT_Optimize Card Order": "Optimize Card Order",
      "HOT_Advantage+ Creative Optimisations": "Advantage+ Creative Optimisations"
    }
  }
  columnWidths () {
    return {
      "default": 100,
      108: ["HOT_Image/Video", "HOT_Video", "HOT_Frame Image"],
      120: ["HOT_Windows Phone Url"],
      160: ["HOT_LineItemFractions", "HOT_Standard_Enhancements", "HOT_Image_Enhancements"],
      200: [
        "HOT_Text",
        "HOT_Description",
        "HOT_See More Url",
        "HOT_See More Display Url",
        "HOT_Link",
        "HOT_Display Link",
        "HOT_Deep Link Destination",
        "HOT_Catalog Options",
        "HOT_News Feed Link Description",
        "HOT_Intro Card: Image",
        "HOT_Intro Card: Headline",
        "HOT_Intro Card: Website Url",
        "HOT_Catalog Card: Creative",
        "HOT_Catalog Card: Headline",
        "HOT_StartDate",
        "HOT_EndDate",
        "HOT_Button Destination",
        "HOT_Deep Link",
        "HOT_ViewTags"
      ],
      230: ["HOT_Catalogue Information"],
      240: [
        "HOT_Intro Card: News Feed Link Description",
        "HOT_Advantage+ Creative Optimisations",
        "- Text",
        "- Link",
        "- Headline",
        "- Image/Video",
        "- Video",
        "- Primary Text",
        "- Description",
        "- Website Url",
        "- Display Link",
        "- Deferred Deep Link"
      ],
      270: ["HOT_Catalog Card: News Feed Link Description"]
    }
  }
  getOptimizationGoals () {
    var objective = this.getObjectiveClass();
    return objective.optimizationGoals;
  }
}
Object.assign(FacebookConfig.prototype, FacebookCampaignLauncherConfig.methods);

export { FacebookConfig }
